import React, { useEffect } from "react";
import { Building2, Briefcase, Landmark } from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "../../config";
import "./index.css";

const LandingPage = () => {
  useEffect(() => {
    // Register service worker for PWA
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").then(
          (registration) => {
            console.log(
              "ServiceWorker registration successful with scope: ",
              registration.scope
            );
          },
          (err) => {
            console.log("ServiceWorker registration failed: ", err);
          }
        );
      });
    }

    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
    });
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-900">
      <div className="max-w-6xl mx-auto px-8 sm:px-6 lg:px-8 py-8 flex-grow">
        <section className="text-center py-6 md:py-6">
          <h1 className="text-2xl md:text-4xl font-bold text-white mb-4 animate-fade-in">
            {config.labels.brandName}
          </h1>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 animate-fade-in">
            Supercharge Your{" "}
            <span id="llg-label">LinkedIn Lead Generation</span> System with AI
          </h1>
          <p className="text-xl md:text-2xl text-gray-400 mb-8">
            Receive{" "}
            <span id="llg-label">50-100+ Qualified Leads in 30 Days</span> or
            Your Money Back!
          </p>
          <p className="text-l md:text-xl text-gray-400 mb-8">
            Over{" "}
            <span id="llg-label">
              <strong>1000+ businesses receiving leads today!</strong>
            </span>
          </p>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-black">
              How We Help You Succeed
            </h2>
            <p className="text-lg mb-4 text-gray-800">
              Our expert team leverages cutting-edge AI technology, your ideal
              customer profile, and your ad campaign data to:
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">
                  Analyze your historical ad performance and audience insights
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">
                  Continuously refine targeting strategies for optimal results
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">
                  Identify high-potential converting leads within your niche
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">
                  Manage 100% of your LinkedIn lead generation efforts
                  effortlessly
                </p>
              </div>
            </div>
            <p className="text-lg font-semibold text-black">
              Let us help you unlock the full potential of LinkedIn for your
              business growth!
            </p>
          </div>
        </section>

        <section className="mb-12 md:mb-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-white">
            Our Pricing
          </h2>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-center mb-4">
              <span className="text-4xl font-bold text-black">$10</span>
              <span className="text-xl ml-2 text-gray-700">
                per high-quality lead
              </span>
            </div>
            <p className="text-center text-lg mb-4 text-gray-800">
              Each lead comes with:
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">Detailed lead profile report</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">
                  AI-crafted personalized introduction
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">Verified lead information</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-gray-700">Link to directly contact lead</p>
              </div>
            </div>
            <p className="text-center text-sm text-gray-600">
              *Only pay for qualified leads that match your specific criteria
            </p>
          </div>
        </section>

        <section className="bg-gray-100 p-6 md:p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-black">
            Start Growing Your Business Today
          </h2>
          <a
            href="https://f4q4dmiym0t.typeform.com/to/ev2dMI4M"
            target="_blank"
            rel="noreferrer"
            type="submit"
            onClick={(_: any) => {
              mixpanel.track("form link clicked");
            }}
            className="w-full bg-black text-center text-white py-3 px-6 rounded text-lg font-semibold hover:bg-gray-800 transition duration-300"
          >
            Get Your <span id="llg-label">FIRST 50 LEADS FOR FREE</span>
          </a>
        </section>

        <section className="mt-12 md:mt-20 text-center bg-black text-white p-8 rounded-lg">
          <h2 className="text-3xl font-bold mb-6">
            Premium Leads from Industry Giants
          </h2>
          <div className="flex justify-center gap-8 mb-8">
            <Building2 className="w-16 h-16 text-white" />
            <Briefcase className="w-16 h-16 text-white" />
            <Landmark className="w-16 h-16 text-white" />
          </div>
          <p className="text-xl mb-6">
            Our AI-powered platform sources high-quality leads from Fortune 500
            companies and fast-growing startups.
          </p>
          <blockquote className="italic max-w-2xl mx-auto text-gray-300">
            "This service has revolutionized our B2B outreach. We're now
            connecting with decision-makers from top-tier companies we never had
            access to before."
            <footer className="mt-2 font-semibold text-white">
              - Sarah Chen, VP of Sales at TechNova
            </footer>
          </blockquote>
        </section>
      </div>

      <footer className="mt-12 py-6 bg-gray-100">
        <div className="container mx-auto px-4">
          <p className="text-center text-gray-600 text-sm">
            Copyright © 2024 AdInsights.ai. All rights reserved |{" "}
            <a
              href="/legal?view=faq"
              target="_blank"
              rel="noreferrer"
              className="text-gray-800 hover:text-black"
            >
              FAQ
            </a>{" "}
            |{" "}
            <a
              href="/legal"
              target="_blank"
              rel="noreferrer"
              className="text-gray-800 hover:text-black"
            >
              Legal
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
